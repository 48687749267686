<template>
  <TableCard display-order :headers="headers" :rows="memberships">
    <template #title>
      {{ $t("app.roles", 2) }}
    </template>

    <template v-if="!hideActions" #title-actions>
      <VSearchResource
        :load="load"
        :disabled="isInputDisabled"
        :format-options="formatLoadOptions"
        @add="onClickAddMembership"
        @selected:option="onSelectedOption"
      />
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.__ACTIONS="{ item }">
      <div class="flex justify-end">
        <VAction
          icon="delete"
          :label="$t('app.delete')"
          :disabled="isDisabled(item?.code)"
          color="text-theme-6"
          @click="$emit('click:confirm', item)"
        />
      </div>
    </template>
  </TableCard>
</template>

<script>
import { inject, ref, computed } from "vue";
import { useStore } from "@/store";
// Composables
import useUserMemberships from "@/composables/useUserMemberships";
import useRole from "@/composables/useRole";
// Components
import TableCard from "@/components/tables/TableCard";
import VAction from "@/components/tables/VAction";
import VSearchResource from "@/components/inputs/VSearchResource";
// Constants
import systemRoles from "@/constants/systemRoles";

export default {
  components: {
    TableCard,
    VAction,
    VSearchResource
  },
  props: {
    memberships: {
      type: Array,
      default: () => []
    },
    hideActions: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    // Misc
    const store = useStore();

    // Inject
    const isAdding = inject("isAdding"); // @NOTE: Provided by User

    // Data
    const isInputDisabled = ref(false);

    // Composables
    const { endpoint, searchFields } = useRole();
    const {
      load,
      formatLoadOptions,
      onClickAddMembership,
      headers
    } = useUserMemberships(props, context, { endpoint, searchFields });

    // Computed
    const isSysAdmin = computed(() => store.getters["user/isSysAdmin"]);

    // Methods
    const isDisabled = code => {
      return !isSysAdmin.value && code === systemRoles.SYSTEM_ADMIN;
    };

    const onSelectedOption = item => {
      if (!isSysAdmin.value && item?.code === systemRoles.SYSTEM_ADMIN) {
        isInputDisabled.value = true;
        return;
      }

      isInputDisabled.value = false;
    };

    return {
      isAdding,
      isDisabled,
      isInputDisabled,
      onSelectedOption,
      // useUserMemberships
      load,
      formatLoadOptions,
      onClickAddMembership,
      headers
    };
  }
};
</script>
