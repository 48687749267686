import { computed } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useOptions from "@/composables/useOptions";

export default function useUserMemberships(props, context, args) {
  // MISC
  const { t } = useI18n();

  // Composables
  const { loadOptions, formatOptions, ALL_OPTIONS } = useOptions();

  // CONSTANTS
  const { endpoint, searchFields } = args;

  // COMPUTED
  const headers = computed(() => {
    const headers = [
      {
        text: t("app.name"),
        value: "name"
      },
      {
        text: t("app.code"),
        value: "code"
      }
    ];

    if (!props.hideActions) {
      headers.push({
        value: "__ACTIONS"
      });
    }

    return headers;
  });

  // METHODS
  const load = query => {
    const filters = [
      {
        f: searchFields,
        c: "LIKE",
        v: [query]
      },
      {
        f: "status",
        c: "EQUAL",
        v: [ALL_OPTIONS.ACTIVE.value]
      }
    ];

    return loadOptions(endpoint, {
      ro_f: encodeURIComponent(JSON.stringify(filters))
    });
  };

  const formatLoadOptions = options => {
    const formattedOptions = formatOptions(options);

    return formattedOptions.map(option => ({
      ...option,
      disabled: isDisabled(option.id)
    }));
  };

  const isDisabled = id => props.memberships.some(m => m.id === id);

  const onClickAddMembership = id => {
    context.emit("click:add", id);
  };

  return {
    load,
    formatLoadOptions,
    onClickAddMembership,
    headers
  };
}
