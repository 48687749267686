<template>
  <VCard>
    <template v-if="$slots.title" #title>
      <slot name="title" />
    </template>

    <template v-if="$slots['title-actions']" #title-actions>
      <slot name="title-actions" />
    </template>

    <template v-if="$slots.tabs" #tabs>
      <slot name="tabs" />
    </template>

    <template #content>
      <VTable
        :multiple="multiple"
        :pagination="pagination"
        :display-order="displayOrder"
        :display-pagination="displayPagination"
        :headers="headers"
        :rows="rows"
        :is-loading="isLoading"
        @update:current_page="$emit('update:current_page', $event)"
      >
        <template v-for="(_, slot) in $slots" #[slot]="props">
          <slot :name="slot" v-bind="props" />
        </template>
      </VTable>
    </template>
  </VCard>
</template>

<script>
import VTable from "@/components/tables/VTable";
import VCard from "@/components/VCard";

export default {
  components: {
    VTable,
    VCard
  },
  props: {
    headers: {
      type: Array,
      default: () => []
    },
    rows: {
      type: Array,
      default: () => []
    },
    displayOrder: {
      type: Boolean,
      default: false
    },
    displayPagination: {
      type: Boolean,
      default: false
    },
    // @TODO: use provide/inject instead
    isLoading: {
      type: Boolean,
      default: false
    },
    // @TODO: use provide/inject instead
    pagination: {
      type: Object,
      default: () => ({
        current_page: 1,
        per_page: 20,
        total: 0
      })
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update:current_page"]
};
</script>
