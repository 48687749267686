<template>
  <TableCard display-order :headers="headers" :rows="memberships">
    <template #title>
      {{ $t("app.departments", 2) }}
    </template>

    <template v-if="!hideActions" #title-actions>
      <VSearchResource
        :load="load"
        :format-options="formatLoadOptions"
        @add="onClickAddMembership"
      />
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.__ACTIONS="{ item }">
      <div class="flex justify-end">
        <VAction
          icon="delete"
          color="text-theme-6"
          @click="$emit('click:confirm', item)"
        />
      </div>
    </template>
  </TableCard>
</template>

<script>
import { inject } from "vue";
// Composables
import useUserMemberships from "@/composables/useUserMemberships";
import useDepartment from "@/composables/useDepartment";
// Components
import TableCard from "@/components/tables/TableCard";
import VAction from "@/components/tables/VAction";
import VSearchResource from "@/components/inputs/VSearchResource";

export default {
  components: {
    TableCard,
    VAction,
    VSearchResource
  },
  props: {
    memberships: {
      type: Array,
      default: () => []
    },
    hideActions: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const isAdding = inject("isAdding"); // @NOTE: Provided by User
    const { endpoint, searchFields } = useDepartment();
    const {
      load,
      formatLoadOptions,
      onClickAddMembership,
      headers
    } = useUserMemberships(props, context, { endpoint, searchFields });

    return {
      isAdding,
      // useUserMemberships
      load,
      formatLoadOptions,
      onClickAddMembership,
      headers
    };
  }
};
</script>
